<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" day-date="true" />

    <v-row>
      <v-col md="4" class="d-flex">
        <h1>UPCOMING JOBS FOR CARLOS</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4">
        <v-btn color="primary" @click="$router.push({ name: 'PrepBidItems' })"
          >Bid Items
        </v-btn>
      </v-col>
      <v-col md="4" class="text-center">
        <v-btn color="primary" @click="$router.push({ name: 'PrepMaterials' })"
          >Materials
        </v-btn>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn color="primary" @click="$router.push({ name: 'PrepChecklist' })"
          >Checklist
        </v-btn>
      </v-col>
    </v-row>
    <datatable :headers="headers" :staticData="items"></datatable>

    <v-row>
      <v-col md="4">
        <v-btn color="primary" @click="$router.push({ name: 'PrepBidItems' })"
          >Bid Items
        </v-btn>
      </v-col>
      <v-col md="4" class="text-center">
        <v-btn color="primary" @click="$router.push({ name: 'PrepMaterials' })"
          >Materials
        </v-btn>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn color="primary" @click="$router.push({ name: 'PrepChecklist' })"
          >Checklist
        </v-btn>
      </v-col>
    </v-row>
    <datatable :headers="headers2" :staticData="items2"></datatable>

    <v-row>
      <v-col md="4">
        <v-btn color="primary" @click="$router.push({ name: 'PrepBidItems' })"
          >Bid Items
        </v-btn>
      </v-col>
      <v-col md="4" class="text-center">
        <v-btn color="primary" @click="$router.push({ name: 'PrepMaterials' })"
          >Materials
        </v-btn>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn color="primary" @click="$router.push({ name: 'PrepChecklist' })"
          >Checklist
        </v-btn>
      </v-col>
    </v-row>
    <datatable :headers="headers3" :staticData="items3"></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "Prep",
  components: { CustomerInfo, Datatable },
  data() {
    return {
      headers: [
        { text: "Carlos", value: "carlos" },
        { text: "Bill Morrison", value: "morrison" },
        { text: "Gladstone", value: "gladstone" },
      ],
      items: [
        {
          carlos: "PIR",
          morrison: "Piers - 11 Ext",
          gladstone: "",
        },
        {
          carlos: "CRR ",
          morrison: "Crack Repair - 32 ft NP1",
          gladstone: "",
        },
        {
          carlos: "F+3/12",
          morrison: "10/25/2020",
          gladstone: "11/02/2020",
        },
      ],
      headers2: [
        { text: "Carlos", value: "erise" },
        { text: "John Costanzo", value: "costanzo" },
        { text: "Kansas City", value: "kansasCity" },
      ],
      items2: [
        {
          erise: "PIR",
          costanzo: "Piers - 15 Ext",
          kansasCity: "",
        },
        {
          erise: "F+3/4",
          costanzo: "11/03/2020",
          kansasCity: "11/06/2020",
        },
      ],
      headers3: [
        { text: "Carlos", value: "carlos" },
        { text: "Cassie Davis", value: "davis" },
        { text: "Grandview", value: "grandview" },
      ],
      items3: [
        {
          carlos: "EXD",
          davis: "Buried Discharge - 24 ft",
          grandview: "",
        },
        {
          carlos: "CRR",
          davis: "Crack Repair -  40 ft epoxy",
          grandview: "",
        },
        {
          carlos: "F+2/1",
          davis: "11/08/2020",
          grandview: "11/08/2020",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
